import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";

import Button from "components/Shared/CustomButtons/Button.js";
import Modal from "components/Shared/CustomModal/CustomModal";
import RecycableInfo from "components/Admin/Collectables/Collectable/Collectable";
import RejectReasonInput from "components/Admin/Collectables/RejectReasonInput";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";

import { useDispatch, useSelector } from "react-redux";
import {
  getCollectable,
  approveSingle,
  rejectSingle,
  bounceSingle,
  getRejectReason,
  reviveSingle,
} from "store/actions/collectables";
import useClasses from "hooks/useClasses";

const styles = (theme) => ({
  mainControlsContainer: {
    position: "sticky",
    top: 0,
    padding: "20px 0",
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignsItems: "center",
    flexWrap: "wrap",
    zIndex: 500,
  },
  controlsContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
    alignsItems: "center",
    flexWrap: "wrap",
  },
  buttonContainer: {
    flex: 1,
    textAlign: "center",
  },
});

const Collectable = () => {
  const [openModal, setOpenModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const classes = useClasses(styles);
  const idsArray = useSelector(
    (state) => state.collectables.collectableIdsArray
  );
  const rejectReasonData = useSelector(
    (state) => state.collectables.rejectReason
  );
  let positionId;
  if (idsArray) {
    positionId = idsArray.indexOf(id);
  }
  const selectedCollectable = useSelector(
    (state) => state.collectables.collectable
  );
  useEffect(() => {
    dispatch(getCollectable(id, history));
  }, [id, dispatch]);

  useEffect(() => {
    dispatch(getRejectReason(history));
  }, [dispatch]);

  const approveHandler = () => {
    setModalContent(
      <div style={{ textAlign: "center" }}>
        <ErrorOutlineRoundedIcon
          color="primary"
          style={{ width: "100px", height: "100px" }}
        />
        <h5>Are You sure want to approve this Recycable ? </h5>
        <div
          style={{
            display: "flex",
            justifyContent: "space-arround",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Button
            color="success"
            size="lg"
            onClick={approveRecycable}
            style={{ flex: 1, margin: "0 10px" }}
          >
            Approve
          </Button>
          <Button
            color="secondary"
            size="lg"
            onClick={() => setOpenModal(false)}
            style={{ flex: 1, margin: "0 10px" }}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
    setOpenModal(true);
  };

  const approveRecycable = () => {
    setOpenModal(false);
    dispatch(approveSingle(id, history));
  };
  const rejectRecycable = (reasonId) => {
    setOpenModal(false);
    dispatch(rejectSingle(id, reasonId, history));
  };
  const bounceRecycable = () => {
    setOpenModal(false);
    dispatch(bounceSingle(id, history));
  };
  const previousHandler = () => {
    history.replace(`/admin/recycables/show/${idsArray[positionId - 1]}`);
  };
  const nextHandler = () => {
    history.replace(`/admin/recycables/show/${idsArray[positionId + 1]}`);
  };
  const rejectHandler = () => {
    setModalContent(
      <div style={{ textAlign: "center" }}>
        <ErrorOutlineRoundedIcon
          color="primary"
          style={{ width: "100px", height: "100px" }}
        />
        <h5>Are You sure want to Reject this recycable ? </h5>
        <RejectReasonInput
          reasonList={rejectReasonData}
          rejectHandler={(reasonId) => rejectRecycable(reasonId)}
          cancelHandler={() => setOpenModal(false)}
        />
      </div>
    );
    setOpenModal(true);
  };

  const bounceHandler = () => {
    setModalContent(
      <div style={{ textAlign: "center" }}>
        <ErrorOutlineRoundedIcon
          color="primary"
          style={{ width: "100px", height: "100px" }}
        />
        <h5>Are You sure want to Bounce this recycable ? </h5>
        <div
          style={{
            display: "flex",
            justifyContent: "space-arround",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Button
            color="danger"
            size="lg"
            onClick={bounceRecycable}
            style={{ flex: 1, margin: "0 10px" }}
          >
            Bounce
          </Button>
          <Button
            color="secondary"
            size="lg"
            onClick={() => setOpenModal(false)}
            style={{ flex: 1, margin: "0 10px" }}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
    setOpenModal(true);
  };
  const reviveHandler = () => {
    setModalContent(
      <div style={{ textAlign: "center" }}>
        <ErrorOutlineRoundedIcon
          color="primary"
          style={{ width: "100px", height: "100px" }}
        />
        <h5>Are You sure want to Revive expiry this recycable ? </h5>
        <div
          style={{
            display: "flex",
            justifyContent: "space-arround",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Button
            color="primary"
            size="lg"
            onClick={reviveRecycable}
            style={{ flex: 1, margin: "0 10px" }}
          >
            Revive
          </Button>
          <Button
            color="secondary"
            size="lg"
            onClick={() => setOpenModal(false)}
            style={{ flex: 1, margin: "0 10px" }}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
    setOpenModal(true);
  };
  const reviveRecycable = () => {
    setOpenModal(false);
    dispatch(reviveSingle(id, history));
  };
  // console.log(selectedCollectable);
  return (
    <div>
      {selectedCollectable && (
        <div>
          <div>
            <Button
              onClick={history.goBack}
              color="transparent"
              style={{ padding: 0 }}
            >
              <ArrowBackIcon style={{ width: "25px", height: "25px" }} />
              <h4 style={{ height: "25px", fontWeight: 900 }}>Back</h4>
            </Button>
          </div>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Recycable Info</h4>
            </CardHeader>
            <CardBody>
              <div className={classes.mainControlsContainer}>
                {typeof positionId !== "undefined" && (
                  <div>
                    {positionId !== 0 && (
                      <Button
                        color="secondary"
                        onClick={previousHandler}
                        disabled={positionId === 0}
                      >
                        <ArrowBackIcon
                          style={{ width: "25px", height: "25px" }}
                        />
                      </Button>
                    )}
                  </div>
                )}
                <div className={classes.controlsContainer}>
                  {selectedCollectable.is_processing === 1 && (
                    <>
                      <div className={classes.buttonContainer}>
                        <Button color="primary" onClick={approveHandler}>
                          Approve
                        </Button>
                      </div>
                      <div className={classes.buttonContainer}>
                        <Button color="danger" onClick={rejectHandler}>
                          Reject
                        </Button>
                      </div>
                    </>
                  )}
                  {selectedCollectable.is_collected === 1 &&
                    selectedCollectable.is_archived === 0 && (
                      <div className={classes.buttonContainer}>
                        <Button color="danger" onClick={bounceHandler}>
                          Bounce
                        </Button>
                      </div>
                    )}
                  {selectedCollectable.is_expired === 1 && (
                    <div className={classes.buttonContainer}>
                      <Button color="primary" onClick={reviveHandler}>
                        Revive Expiry
                      </Button>
                    </div>
                  )}
                </div>
                {typeof idsArray !== "undefined" &&
                typeof positionId !== "undefined" &&
                positionId < idsArray.length - 1 ? (
                  <div>
                    <Button color="secondary" onClick={nextHandler}>
                      <ArrowForwardIcon
                        style={{ width: "25px", height: "25px" }}
                      />
                    </Button>
                  </div>
                ) : (
                  <div>
                    <Button color="secondary" onClick={history.goBack}>
                      Return
                    </Button>
                  </div>
                )}
              </div>
              <RecycableInfo collectable={selectedCollectable} />
            </CardBody>
          </Card>
        </div>
      )}
      <Modal open={openModal} handleClose={() => setOpenModal(false)}>
        {modalContent}
      </Modal>
    </div>
  );
};

export default Collectable;
