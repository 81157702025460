import axios from "axios";
import * as api from "../api";
import * as actionsType from "./actionsType";
import { errorHandler } from "./general";

export const getBranches = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_BRANCHES_COMPANY_ADMIN,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.getBranchesCompanyAdmin(formData);
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    dispatch({ type: actionsType.GET_BRANCHES_COMPANY_ADMIN, payload: data });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const getBranch = (id, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_BRANCH_COMPANY_ADMIN,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const branchRes = await api.getBranchCompanyAdmin(id);
    dispatch({
      type: actionsType.GET_BRANCH_COMPANY_ADMIN,
      payload: branchRes.data.data,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const getBranchCollected = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_BRANCH_COLLECTED_COMPANY_ADMIN,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_BRANCH_ARRAY_COMPANY_ADMIN,
      payload: [],
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.getBranchCollectedCompanyAdmin(formData);
    let idsArray = [];
    data.data.data.map((d) => idsArray.push(d.id));
    dispatch({
      type: actionsType.GET_BRANCH_COLLECTED_COMPANY_ADMIN,
      payload: data.data,
    });
    dispatch({
      type: actionsType.SET_BRANCH_ARRAY_COMPANY_ADMIN,
      payload: idsArray,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const getBranchNotCollected =
  (formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.GET_BRANCH_NOT_COLLECTED_COMPANY_ADMIN,
        payload: null,
      });
      dispatch({
        type: actionsType.SET_BRANCH_ARRAY_COMPANY_ADMIN,
        payload: [],
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      const { data } = await api.getBranchNotCollectedCompanyAdmin(formData);
      let idsArray = [];
      data.data.data.map((d) => idsArray.push(d.id));
      dispatch({
        type: actionsType.GET_BRANCH_NOT_COLLECTED_COMPANY_ADMIN,
        payload: data.data,
      });
      dispatch({
        type: actionsType.SET_BRANCH_ARRAY_COMPANY_ADMIN,
        payload: idsArray,
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const exportBranchCollectedPdf =
  (formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      const { data } = await api.branchCollectedPdfCompanyAdmin(formData);
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
      window.open(data, "_blank");
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const getBranchArchivedBatches =
  (formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.GET_BRANCH_ARCHIVED_BATCHES_COMPANY_ADMIN,
        payload: null,
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      const { data } = await api.getBranchArchivedBatchesCompanyAdmin(formData);
      dispatch({
        type: actionsType.GET_BRANCH_ARCHIVED_BATCHES_COMPANY_ADMIN,
        payload: data,
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const getBranchArchivedItems =
  (formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.GET_BRANCH_ARCHIVED_ITEMS_COMPANY_ADMIN,
        payload: null,
      });
      dispatch({
        type: actionsType.GET_ARCHIVED_BATCH_COMPANY_ADMIN,
        payload: null,
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      dispatch({
        type: actionsType.SET_BRANCH_ARRAY_COMPANY_ADMIN,
        payload: [],
      });
      const res = await api.getArchivedBatchCompanyAdmin(formData);
      const archivedBatch = res.data.data;
      // console.log({ archivedBatch });
      const { data } = await api.getBranchArchivedItemsCompanyAdmin(formData);
      let idsArray = [];
      data.data.data.map((d) => idsArray.push(d.id));
      dispatch({
        type: actionsType.GET_ARCHIVED_BATCH_COMPANY_ADMIN,
        payload: archivedBatch,
      });
      dispatch({
        type: actionsType.GET_BRANCH_ARCHIVED_ITEMS_COMPANY_ADMIN,
        payload: data.data,
      });
      dispatch({
        type: actionsType.SET_BRANCH_ARRAY_COMPANY_ADMIN,
        payload: idsArray,
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const getCollectable = (id, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_COLLECTABLE_COMPANY_ADMIN,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.getCollectableCompanyAdmin(id);
    dispatch({
      type: actionsType.GET_COLLECTABLE_COMPANY_ADMIN,
      payload: data.data,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const companyAdminExportReport =
  (formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      const { data } = await api.companyAdminExportReport(formData);
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
      console.log(data);
      if (formData.document_type === "pdf") {
        axios
          .get(data, {
            responseType: "blob", // important
          })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${Date.now()}.pdf`);
            document.body.appendChild(link);
            link.click();
          });
      } else {
        axios
          .get(data, {
            responseType: "blob", // important
          })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${Date.now()}.xlsx`);
            document.body.appendChild(link);
            link.click();
          });
      }

      // window.open(data, "_blank");
      // console.log(data);
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };
