import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
  CssBaseline,
  useMediaQuery,
} from "@mui/material";
import queryString from "query-string";

import CustomMessage from "components/Shared/CustomMessage/CustomMessage";

import Admin from "layouts/Admin/Admin";
import Home from "./views/Home/Home";
import Login from "./views/Auth/Login";
import ForgotPass from "./views/Auth/ForgotPass";
import ResetPass from "./views/Auth/ResetPass";
import SetPass from "./views/Auth/SetPass";
import Awareness from "./views/Main/Awareness/Awareness";
import Copyright from "./views/Main/Copyright/Copyright";
import Recycles from "./views/Main/Recycles/Recycles";
import Policy from "./views/Main/Policy/Policy";

import { ColorModeContext } from "variables/colorContext";
import { green, lightBlue } from "@mui/material/colors";
import { getAccount } from "store/actions/auth";
import CustomLoading from "components/Shared/CustomLoading/CutomLoading";
import CustomLocalMessage from "components/Shared/CustomMessage/CustomLocalMessage";
import { messaging, requestToken } from "./firebase";
import { getToken, onMessage } from "firebase/messaging";

const App = () => {
  const [notificationMessage, setNotificationMessage] = useState(null);
  const preferedMode = localStorage.getItem("mode");
  const [mode, setMode] = React.useState();
  const light = "#4A7EFF";
  const dark = "#000760";
  const lightOrange = "#FF9900";
  const darkOrange = "#CC6600";

  React.useEffect(() => {
    setMode(preferedMode === "dark" ? "dark" : "light");
    if (!preferedMode) {
      localStorage.setItem("mode", "dark");
    }
  }, [preferedMode]);

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          if (prevMode === "light") {
            localStorage.setItem("mode", "dark");
            return "dark";
          } else {
            localStorage.setItem("mode", "light");
            return "light";
          }
        });
      },
    }),
    []
  );
  let theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: mode,
          primary: {
            main: mode === "light" ? dark : light, //Dark Blue : Light BLue
            mainColor: mode === "light" ? dark : light, //Dark Blue : Light BLue
            contentText: mode === "light" ? "#fff" : "#000",
          },
          secondary: {
            main: mode === "light" ? darkOrange : lightOrange, //Dark Blue : Light BLue
            mainColor: mode === "light" ? darkOrange : lightOrange, //Dark Blue : Light BLue
            contentText: mode === "light" ? "#fff" : "#000",
          },
          background: {
            default: mode === "light" ? "#FFFFFF" : "#000000",
            paper: mode === "light" ? "#EDF0F1" : "#1C1C1E",
            secondary: mode === "light" ? "#EEEEEE" : "#121212",
          },
        },
        typography: {
          fontFamily: "'Cairo', sans-serif",
          fontWeight: "300",
          lineHeight: "1.5em",
        },
      }),
    [mode]
  );
  theme = responsiveFontSizes(theme);
  const dispatch = useDispatch();
  const history = useHistory();
  const authData = useSelector((state) => state.auth.authData);
  if (!authData) {
    dispatch(getAccount(history));
  }
  const { email, token, content } = queryString.parse(history.location.search);

  async function requestPermission() {
    //requesting permission using Notification API
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      const swRegistration = await navigator.serviceWorker.register(
        "/firebase-messaging-sw.js"
      );
      const notificationToken = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_VAPID_KEY,
        serviceWorkerRegistration: swRegistration,
      });

      //We can send token to server
      if (notificationToken) {
        localStorage.setItem("nt", notificationToken);
      }
    } else if (permission === "denied") {
      localStorage.removeItem("nt");
      //notifications are blocked
      alert("You denied for the notification");
    }
  }

  useEffect(() => {
    navigator.serviceWorker.ready.then(() => {
      requestPermission();
    });
  }, []);

  // useEffect(() => {
  onMessage(messaging, (payload) => {
    // console.log({ payload });
    setNotificationMessage({
      text: payload.notification.title + " - " + payload.notification.body,
      type: "info",
    });
  });
  // }, [onMessage]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <React.Fragment>
          {notificationMessage && (
            <CustomLocalMessage
              message={notificationMessage}
              closeMessage={() => setNotificationMessage(null)}
              messageClickHandler={() => history.push(`/admin/notifications`)}
            />
          )}

          <CustomMessage />
          <CustomLoading />
          <Switch>
            <Route
              exact
              path="/"
              component={() =>
                email && token && !localStorage.getItem("token") ? (
                  content === "reset-password" ? (
                    <Redirect
                      to={`/reset-password?email=${email}&token=${token}`}
                    />
                  ) : content === "setup-password" ? (
                    <Redirect
                      to={`/set-password?email=${email}&token=${token}`}
                    />
                  ) : (
                    <Home />
                  )
                ) : (
                  <Home />
                )
              }
            />
            <Route
              path="/login"
              component={() =>
                localStorage.getItem("token") ? <Redirect to="/" /> : <Login />
              }
            />
            <Route
              path="/forgot-password"
              component={() =>
                localStorage.getItem("token") ? (
                  <Redirect to="/" />
                ) : (
                  <ForgotPass />
                )
              }
            />
            <Route
              path="/reset-password"
              component={(props) =>
                localStorage.getItem("token") ? (
                  <Redirect to="/" />
                ) : (
                  <ResetPass {...props} />
                )
              }
            />
            <Route
              path="/set-password"
              component={(props) =>
                localStorage.getItem("token") ? (
                  <Redirect to="/" />
                ) : (
                  <SetPass {...props} />
                )
              }
            />
            <Route path="/awareness" component={Awareness} />
            <Route path="/copyright" component={Copyright} />
            <Route path="/recycles" component={Recycles} />
            <Route path="/policy" component={Policy} />
            <Route
              path="/admin"
              component={() =>
                localStorage.getItem("token") ? <Admin /> : <Redirect to="/" />
              }
            />
          </Switch>
        </React.Fragment>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
