/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import { NavLink, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import useClasses from "hooks/useClasses";

// @mui/material components
import useMediaQuery from "@mui/material/useMediaQuery";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
// core components
// import AdminNavbarLinks from "components/Shared/Navbars/AdminNavbarLinks.js";
import SidebarLink from "./SidebarLink";
import { userLogout } from "store/actions/auth";
import bgImage from "assets/img/bgImg.jpg";
import styles from "./sidebarStyle";

export default function Sidebar(props) {
  const classes = useClasses(styles);
  const isMobile = useMediaQuery("(max-width:899px)");
  let location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return location.pathname === routeName;
  }
  const { logo, routes } = props;
  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => (
        <SidebarLink
          route={prop}
          key={key}
          activeRoute={activeRoute}
          clickHandler={isMobile ? props.handleDrawerToggle : () => {}}
        />
      ))}
    </List>
  );
  var brand = (
    <div className={classes.logo}>
      <NavLink to="/" className={classNames(classes.logoLink)}>
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
      </NavLink>
    </div>
  );

  const handleLogout = () => {
    dispatch(userLogout(history));
  };
  const handleAccount = () => {
    history.push("/admin/profile");
  };

  return (
    <div>
      {isMobile ? (
        <Drawer
          variant="temporary"
          anchor="right"
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper),
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <div
            className={classes.background}
            style={{ backgroundImage: "url(" + bgImage + ")" }}
          >
            {brand}
            <div className={classes.sidebarWrapperContainer}>
              <div className={classes.sidebarWrapper}>
                {links}
                <div style={{ marginTop: "40px", marginButton: "20px" }}>
                  <ListItem
                    button
                    className={classes.itemLink}
                    onClick={handleAccount}
                  >
                    <ListItemText
                      style={{ color: "#fff" }}
                      primary="Account"
                      className={classes.itemText}
                      disableTypography={true}
                    />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.itemLink}
                    onClick={handleLogout}
                  >
                    <ListItemText
                      style={{ color: "#fff" }}
                      primary="Logout"
                      className={classes.itemText}
                      disableTypography={true}
                    />
                  </ListItem>
                </div>
              </div>
              <div style={{ zIndex: 4 }}>
                <span style={{ color: "#fff" }}>
                  <strong style={{ display: "block" }}>
                    Contact support:{" "}
                  </strong>
                  <a
                    href="tel:+201063412170"
                    target="_blank"
                    rel="noopener"
                    style={{ color: "#fff", textDecoration: "none" }}
                  >
                    +201063412170
                  </a>
                </span>
              </div>
            </div>
          </div>
        </Drawer>
      ) : (
        <Drawer
          anchor="left"
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper),
          }}
        >
          <div
            className={classes.background}
            style={{ backgroundImage: "url(" + bgImage + ")" }}
          >
            {brand}
            <div className={classes.sidebarWrapperContainer}>
              <div className={classes.sidebarWrapper}>{links}</div>
              <div style={{ zIndex: 4 }}>
                <span style={{ color: "#fff" }}>
                  <strong style={{ display: "block" }}>
                    Contact support:{" "}
                  </strong>
                  <a
                    href="tel:+201063412170"
                    target="_blank"
                    rel="noopener"
                    style={{ color: "#fff", textDecoration: "none" }}
                  >
                    +201063412170
                  </a>
                </span>
              </div>
            </div>
          </div>
        </Drawer>
      )}
    </div>
  );
}
