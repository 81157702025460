import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";

import Button from "components/Shared/CustomButtons/Button.js";
import Modal from "components/Shared/CustomModal/CustomModal";
import RecycableInfo from "components/Admin/Collectables/Collectable/Collectable";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";

import useClasses from "hooks/useClasses";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";

import { useDispatch, useSelector } from "react-redux";
import CompanyComponent from "components/Admin/Companies/Company/Company";
import { updateCompany, getCompany } from "store/actions/companies";
import CompanyNumbers from "components/Admin/Companies/CompanyNumbers/CompanyNumbers";

const styles = (theme) => ({
  controlsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignsItems: "center",
    flexWrap: "wrap",
  },
});

const Company = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const classes = useClasses(styles);
  const { company, companyNumbers } = useSelector((state) => state.companies);
  useEffect(() => {
    dispatch(getCompany(id, history));
  }, [id, dispatch]);

  const updateHandler = (data) => {
    data.id = id;
    dispatch(updateCompany(data, history));
  };

  console.log(company);
  return (
    <div>
      {company && (
        <>
          <CompanyNumbers companyNumbers={companyNumbers} />
          <div>
            <div>
              <Button
                onClick={history.goBack}
                color="transparent"
                style={{ padding: 0 }}
              >
                <ArrowBackIcon style={{ width: "25px", height: "25px" }} />
                <h4 style={{ height: "25px", fontWeight: 900 }}>Back</h4>
              </Button>
            </div>
            <Card>
              <CardHeader color="primary">
                <h4>Company Info</h4>
              </CardHeader>
              <CardBody>
                <CompanyComponent
                  company={company}
                  isCreate={false}
                  handleUpdate={updateHandler}
                  handleCreate={null}
                />
              </CardBody>
            </Card>
          </div>
          {/* 
      <Modal open={openModal} handleClose={() => setOpenModal(false)}>
      {modalContent}
    </Modal> */}
        </>
      )}
    </div>
  );
};

export default Company;
