import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";

import Button from "components/Shared/CustomButtons/Button.js";
import Modal from "components/Shared/CustomModal/CustomModal";
import RecycableInfo from "components/Admin/Collectables/Collectable/Collectable";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";

import useClasses from "hooks/useClasses";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";

import { useDispatch, useSelector } from "react-redux";
import Company from "components/Admin/Companies/Company/Company";
import { createCompany } from "store/actions/companies";

const styles = (theme) => ({
  controlsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignsItems: "center",
    flexWrap: "wrap",
  },
});

const CreateCompany = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useClasses(styles);

  const createHandler = (newData) => {
    console.log(newData);
    dispatch(createCompany(newData, history));
  };

  return (
    <div>
      <div>
        <div>
          <Button
            onClick={history.goBack}
            color="transparent"
            style={{ padding: 0 }}
          >
            <ArrowBackIcon style={{ width: "25px", height: "25px" }} />
            <h4 style={{ margin: "auto", fontWeight: 900 }}>Back</h4>
          </Button>
        </div>
        <Card>
          <CardHeader color="primary">
            <h4>Company Info</h4>
          </CardHeader>
          <CardBody>
            <Company
              company={null}
              isCreate={true}
              handleUpdate={null}
              handleCreate={createHandler}
            />
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default CreateCompany;
