import * as api from "../api";
import * as actionsType from "./actionsType";
import { errorHandler } from "./general";

export const getCompanies = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_COMPANIES,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.getCompanies(formData);
    dispatch({
      type: actionsType.GET_COMPANIES,
      payload: data,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const deleteCompany =
  (idsArray, formData, history) => async (dispatch) => {
    try {
      await api.deleteCompany({
        ids: idsArray,
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      dispatch({
        type: actionsType.GET_COMPANIES,
        payload: null,
      });
      const { data } = await api.getCompanies(formData);
      dispatch({
        type: actionsType.GET_COMPANIES,
        payload: data,
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "Company deleted Successfully",
        },
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const getCompany = (id, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_COMPANY,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const companyRes = await api.getCompany(id);
    dispatch({
      type: actionsType.GET_COMPANY,
      payload: companyRes.data.data,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    const companyNumbers = await api.getCompanyAnalysisCollectables(id);
    dispatch({
      type: actionsType.GET_COMPANY_NUMBERS,
      payload: companyNumbers.data.data,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const createCompany = (newData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const formData = new FormData();
    formData.append("name", newData.name);
    formData.append("name_ar", newData.name_ar);
    formData.append("phone", newData.phone);
    formData.append("description", newData.description);
    formData.append("description_ar", newData.description_ar);
    formData.append("logo", newData.logo);
    formData.append("icon", newData.icon);
    formData.append("address", newData.address);
    formData.append("address_ar", newData.address_ar);
    formData.append("has_pickup", newData.has_pickup);
    formData.append("has_dropoff", newData.has_dropoff);
    formData.append("link_website", newData.link_website);
    await api.createCompany(formData);
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "New Company has been created successfully",
      },
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    history.push("/admin/companies");
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const updateCompany = (newData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_COMPANY,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const formData = new FormData();
    formData.append("id", newData.id);
    formData.append("name", newData.name);
    formData.append("name_ar", newData.name_ar);
    formData.append("phone", newData.phone);
    formData.append("description", newData.description);
    formData.append("description_ar", newData.description_ar);
    if (newData.logo) {
      formData.append("logo", newData.logo);
    }
    if (newData.icon) {
      formData.append("icon", newData.icon);
    }
    formData.append("address", newData.address);
    formData.append("address_ar", newData.address_ar);
    formData.append("link_website", newData.link_website);
    formData.append("has_pickup", newData.has_pickup);
    formData.append("has_dropoff", newData.has_dropoff);
    await api.updateCompany(formData);
    const companyRes = await api.getCompany(newData.id);
    dispatch({
      type: actionsType.GET_COMPANY,
      payload: companyRes.data.data,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "Company has been updated successfully",
      },
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const getCompanyCollectables =
  (formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.GET_COMPANY_COLLECTABLES,
        payload: null,
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      dispatch({
        type: actionsType.SET_COMPANY_COLLECTABLES_ARRAY,
        payload: [],
      });
      const { data } = await api.getCompanyCollectables(formData);
      console.log(data);
      let idsArray = [];
      data.data.map((d) => idsArray.push(d.id));
      const companyNumbers = await api.getCompanyAnalysisCollectables(
        formData.company_id
      );
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
      dispatch({
        type: actionsType.GET_COMPANY_NUMBERS,
        payload: companyNumbers.data.data,
      });
      dispatch({
        type: actionsType.GET_COMPANY_COLLECTABLES,
        payload: data,
      });
      dispatch({
        type: actionsType.SET_COMPANY_COLLECTABLES_ARRAY,
        payload: idsArray,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };
