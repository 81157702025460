import React, { useState } from "react";
import {
  Avatar,
  Typography,
  Container,
  Paper,
  Grid,
  InputLabel,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { login } from "store/actions/auth";
import { useHistory, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

import Button from "components/Shared/CustomButtons/Button.js";
import Input from "components/Shared/CustomInput/Input";
import useClasses from "hooks/useClasses";

import styles from "./styles";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string("Enter your password").required("Password is required"),
});

const Login = () => {
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const [showPassword, setShowPassword] = useState(false);
  // const [formData, setFormData] = useState(initialState);
  const classes = useClasses(styles);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = (values) => {
    dispatch(login(values, history));
  };
  const handleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className={classes.main}>
      <Container component="main" maxWidth="sm">
        <Paper className={classes.paper} elevation={3}>
          <div className={classes.header}>
            {/* <Avatar className={classes.avatar}>
              <Icon />
            </Avatar> */}
            <Typography className={classes.title} variant="h4">
              Login
            </Typography>
          </div>
          <form className={classes.form} onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <>
                <InputLabel style={{ fontWeight: "bold", marginLeft: "10px" }}>
                  Email:
                </InputLabel>
                <Input
                  id="email"
                  name="email"
                  label="Email address"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  type="email"
                />
              </>
              <>
                <InputLabel style={{ fontWeight: "bold", marginLeft: "10px" }}>
                  Password:
                </InputLabel>
                <Input
                  id="password"
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  handleShowPassword={handleShowPassword}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
              </>
            </Grid>
            <Button
              color="primary"
              fullWidth
              variant="contained"
              className={classes.submit}
              type="submit"
            >
              Login
            </Button>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <span>
                  <strong>Contact support: </strong>
                  <a
                    href="tel:+201063412170"
                    target="_blank"
                    rel="noopener"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <span>+201063412170</span>
                  </a>
                </span>
              </Grid>
              <Grid item>
                <Button
                  component={Link}
                  to="/forgot-password"
                  color="transparent"
                >
                  Forgot Password?
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    </div>
  );
};

export default Login;
