import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ClientCartItemsTable from "components/Admin/Users/Clients/Client/ClientCartList";

import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";

import { getClientCartItems } from "store/actions/usersClients";

const ClientCartItems = () => {
  const cartItemsData = useSelector(
    (state) => state.usersClients.clientCartItems
  );
  const dispatch = useDispatch();
  const { id } = useParams();
  const [page, setPage] = useState(cartItemsData ? cartItemsData.pag.page : 1);
  const [amount, setAmount] = useState(
    cartItemsData ? cartItemsData.pag.amount : 10
  );
  const [orderField, setOrderField] = useState(
    cartItemsData ? cartItemsData.pag.order_field : "created_at"
  );
  const [orderType, setOrderType] = useState(
    cartItemsData ? cartItemsData.pag.order_type : "desc"
  );
  const [searchValue, setSearchValue] = useState("");
  const [searchBy, setSearchBy] = useState(
    cartItemsData ? cartItemsData.pag.search_by : "item_name"
  );
  const history = useHistory();

  useEffect(() => {
    dispatch(
      getClientCartItems(
        {
          client_id: id,
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
        },
        history
      )
    );
  }, [id, page, amount, orderField, orderType, searchValue, dispatch]);

  const showRecycableHandler = (id) => {
    history.push("/admin/recycables/show/" + id);
  };

  console.log({ cartItemsData });
  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4>Client Cart Items</h4>
        </CardHeader>
        <CardBody>
          {cartItemsData && (
            <ClientCartItemsTable
              rows={cartItemsData.data}
              totalRows={cartItemsData.pag.total}
              rowsPerPage={amount}
              setRowsPerPage={setAmount}
              order={orderType}
              setOrder={setOrderType}
              orderBy={orderField}
              setOrderBy={setOrderField}
              showRecycable={showRecycableHandler}
              page={page - 1}
              setPage={setPage}
              searchHandler={setSearchValue}
              searchValue={searchValue}
              selectedSearchBy={searchBy}
              handleSearchByChange={setSearchBy}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default ClientCartItems;
