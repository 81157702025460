import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ClientTransactionsTable from "components/Admin/Users/Clients/Client/ClientTransactionList";

import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";

import { getClientTransactions } from "store/actions/usersClients";

const ClientTransactions = () => {
  const transactionData = useSelector(
    (state) => state.usersClients.clientTransactions
  );
  const dispatch = useDispatch();
  const { id } = useParams();
  const [page, setPage] = useState(
    transactionData ? transactionData.pag.page : 1
  );
  const [amount, setAmount] = useState(
    transactionData ? transactionData.pag.amount : 10
  );
  const [orderField, setOrderField] = useState(
    transactionData ? transactionData.pag.order_field : "created_at"
  );
  const [orderType, setOrderType] = useState(
    transactionData ? transactionData.pag.order_type : "desc"
  );
  const [searchValue, setSearchValue] = useState("");
  const [searchBy, setSearchBy] = useState(
    transactionData ? transactionData.pag.search_by : "company_name"
  );
  const history = useHistory();

  useEffect(() => {
    dispatch(
      getClientTransactions(
        {
          client_id: id,
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
        },
        history
      )
    );
  }, [id, page, amount, orderField, orderType, searchValue, dispatch]);

  console.log({ transactionData });

  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4>Client Transactions</h4>
        </CardHeader>
        <CardBody>
          {transactionData && (
            <ClientTransactionsTable
              rows={transactionData.data}
              totalRows={transactionData.pag.total}
              rowsPerPage={amount}
              setRowsPerPage={setAmount}
              order={orderType}
              setOrder={setOrderType}
              orderBy={orderField}
              setOrderBy={setOrderField}
              page={page - 1}
              setPage={setPage}
              searchHandler={setSearchValue}
              searchValue={searchValue}
              selectedSearchBy={searchBy}
              handleSearchByChange={setSearchBy}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default ClientTransactions;
